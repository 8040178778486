import React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { Box, BoxProps, HTMLChakraProps, chakra } from '@chakra-ui/react';

const style = {
  width: 20,
  height: 20,
  opacity: 1,
  margin: 4,
  borderRadius: 20,
  display: 'inline-block',
  background: '#078942',
};

const variants = {
  start: {
    scale: 0.2,
    rotate: 0,
  },
  end: {
    scale: 0.8,
    rotate: 360,
  },
};

type Merge<P, T> = Omit<P, keyof T> & T;

type MotionBoxProps = Merge<HTMLChakraProps<'div'>, HTMLMotionProps<'div'>>;

export const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div);

const Loader = (props: any) => {
  return (
    <>
      <MotionBox
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 0.6,
          delay: 0,
        }}
      />
      <MotionBox
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 0.6,
          delay: 0.1,
        }}
      />
      <MotionBox
        style={style}
        variants={variants}
        initial={'start'}
        animate={'end'}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'anticipate',
          duration: 0.6,
          delay: 0.2,
        }}
      />
    </>
  );
};

export default Loader;
