import { useState, useEffect } from 'react';
import { PAYMENT_PROVIDERS } from '../constants';


// Define a collection of countries for each provider
const PROVIDER_COUNTRIES = {
    [PAYMENT_PROVIDERS.STRIPE]: ['US', 'FR'],
    [PAYMENT_PROVIDERS.KKIA_PAY]: ['TG', 'BJ', 'SN', 'CI'],
    // Add more providers and their respective countries as needed
};

const useGetPaymentProvider = (country: string) => {
    const [countryProvider, setCountryProvider] = useState(PAYMENT_PROVIDERS.KKIA_PAY);

    useEffect(() => {
        if (country) {            
            // Iterate through the providers and check if the country belongs to one of them
            for (const provider in PROVIDER_COUNTRIES) {
                if (PROVIDER_COUNTRIES[provider].includes(country)) {
                    setCountryProvider(provider);
                    return; // Exit the loop once a provider is found
                }
                
            }
        }
    }, [country]);

    return countryProvider;
};

export default useGetPaymentProvider;
