import * as React from 'react';
import { Flex, Box, Heading, Text, Stack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
const TokenError: React.FC = () => {
  return (
    <Stack
      color={'black'}
      bg={'white'}
      position={'relative'}
      justifyContent="center"
      justifyItems={'center'}
      height={'100%'}
    >
      <Box width={'100%'}>
        <Heading>
          <Trans>Erreur de jeton</Trans>
        </Heading>
        <Text pt={4}>
          <Trans>Le jetons que vous voulez utiliser est invalide. Veuillez réessayer.</Trans>
        </Text>
      </Box>
    </Stack>
  );
};

export default TokenError;
