import React from 'react';
import { Flex, Box, Container } from '@chakra-ui/react';
import Loader from './Loader';

const FullPageLoader = (props: any) => {
  return (
    <Flex
      width={'100%'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'white'}
    >
      <Loader />
    </Flex>
  );
};

export default FullPageLoader;
