import React, { useState } from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import PaymentMethods from '../Payment/PaymentMethods';
import InvoicePaymentSuccess from '../Invoice/InvoicePaymentSuccess';

const PaymentMethodModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  invoiceToken: string;
  invoiceReference: string;
}> = ({ isOpen, onClose, invoiceToken, invoiceReference }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <ModalContent mt={{ base: 100, md: 200 }} p={0} h="500px">
        <ModalBody p={0}>
          {paymentSuccess ? (
            <InvoicePaymentSuccess invoiceReference={invoiceReference} />
          ) : (
            <PaymentMethods
              selectedTab={selectedTab}
              onTabChange={handleTabChange}
              onPaymentSuccess={handlePaymentSuccess}
              invoiceToken={invoiceToken}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentMethodModal;
