import React from 'react';
import {
  Box,
  Text,
  Flex,
  VStack,
  Stack,
  Button,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { Trans } from '@lingui/macro';

type ResponseProps = {
  // invoice: IInvoice,
  // company: ICompany,
  // customer: ICustomer,
  // totals: ITotals,
  // currency: string
};
const InvoiceContentMobile = ({ datas, onPaymentClick }: any) => {
  const textColor = useColorModeValue('#858585', 'white');

  return (
    <Flex>
      <Box w="full" mt={120} mx={10} px={10} py={3} bg={'white'}>
        <Box justifyItems={'center'}>
          <Heading fontSize={'lg'}>
            <Trans>Facture</Trans> {datas.invoice.reference_code}
          </Heading>
          <Box>
            <Stack direction={['column', 'row']}>
              <Text><Trans>Emise le</Trans>: </Text>
              <Text>{datas.invoice.emission_at}</Text>
            </Stack>
            <Stack direction={['column', 'row']}>
              <Text><Trans>Date échéance</Trans>:</Text>
              <Text>{datas.invoice.due_at}</Text>
            </Stack>
            {datas.invoice?.paid_at && (
            <Stack direction={['column', 'row']}>
              <Text><Trans>Date de paiement</Trans>:</Text>
              <Text>{datas.invoice.paid_at}</Text>
            </Stack>)}
            <Stack direction={['column', 'row']}>
              <Text><Trans>Emise Par</Trans>:</Text>
              <Text> {datas.company.name}</Text>
            </Stack>
          </Box>

          <Box width="100%" mt={5} borderTop="1px solid #eeee">
            <Box display={'flex'} justifyContent="space-between" pt={3}>
              <Text><Trans>Total montant</Trans>:</Text>
              <Text>{datas.currency}{' '}{datas.totals.sub}</Text>
            </Box>
            <Box display={'flex'} justifyContent="space-between" pt={1}>
              <Text><Trans>Montant Taxes</Trans>:</Text>
              <Text>{datas.currency}{' '}{datas.totals.taxes}</Text>
            </Box>
            <Box
              display={'flex'}
              justifyContent="space-between"
              pt={4}
              fontWeight="bold"
            >
              <Text><Trans>Montant Total TTC</Trans>:</Text>
              <Text fontSize={'xl'}>
                
                {datas.currency}{' '}{datas.totals.sub}{' '}
              </Text>
            </Box>
          </Box>
        </Box>
        <Flex my={50}>
          <VStack w="100%">
          {!datas.invoice?.paid_at && (
            <Button
              leftIcon={<AiOutlineCreditCard />}
              bg={'#078942'}
              color={'white'}
              height={37}
              w={'100%'}
              onClick={onPaymentClick}
            >
              <Trans>Payer la facture</Trans>
            </Button>)}
            <Button w={'100%'}><Trans>Télécharger le PDF</Trans></Button>
          </VStack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default InvoiceContentMobile;
