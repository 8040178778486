import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Button,
  VStack,
  Box,
  Stack,
  useMediaQuery,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import { AiOutlineCreditCard, AiOutlineDownload } from "react-icons/ai";
import FullPageLoader from "../components/Loaders/Fullpage.loader";
import InvoiceContent from "../components/Invoice/Content";
import InvoiceFooter from "../components/Invoice/Footer";
import useAxios from "hooks/useAxios";
import InvoiceContentMobile from "../components/Invoice/ContentMobile";
import { downloadFile } from "shared/utils";
import PaymentMethodModal from "../components/Modal/PaymentMethodModal";

import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";
import useGetPaymentProvider from "../hooks/useGetPaymentProvider";
import { PAYMENT_PROVIDERS } from "../constants";
import useKKiaPay from "../hooks/useKKiapay";

const Invoice: React.FC<{}> = (): JSX.Element => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const query: any = useParams();
  const [renderModal, setRenderModal] = useState<boolean>(false);

  const { response, loading, error } = useAxios({
    method: "POST",
    url: `/payments/check_token/`,
    data: {
      token: query.token,
    },
  });

  // Use the custom hook to find the payment provider based on the response data
  const paymentProvider = useGetPaymentProvider(
    response?.data?.company?.country
  );

  // Use the KKiaPay custom hook
  const { openKKiaPayModal, handleKKiaPaySuccess } = useKKiaPay();

  const [isPaymentProcessing, setIsPaymentProcessing] =
    useState<boolean>(false);

  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");

  useEffect(() => {
    if (renderModal) {
      onOpen();
    } else {
      onClose();
    }
  }, [renderModal]);

  const handlePayment = () => {
    switch (paymentProvider) {
      case PAYMENT_PROVIDERS.KKIA_PAY:
        // Open the KKiaPay modal using the custom hook
        setIsPaymentProcessing(true);
        openKKiaPayModal({
          company: {
            id: response?.data.company?.id,
            country: response?.data.company?.country,
          },
          invoice: {
            amount: response?.data.totals?.in_number,
            invoice_id: response?.data.invoice?.invoice_id,
            user_id: response?.data.invoice?.user,
          },
          customer: {
            name: response?.data.customer?.name,
            email: response?.data.customer?.email,
            phone: response?.data.customer?.phone,
          },
        });
        // Handle KKiaPay success using the custom hook
        handleKKiaPaySuccess(
          (response) => {
            // Handle KKiaPay success response here
            const queryParams = new URLSearchParams(window.location.search);
            const queryString = queryParams.toString();

            // Delay for 3 seconds before redirecting
            setTimeout(() => {
              window.location.href = `${window.location.pathname}?${queryString}`;
            }, 3000);
          });
        break;
      case PAYMENT_PROVIDERS.STRIPE:
      default:
        setRenderModal(true);
        setIsPaymentProcessing(true);
        break;
    }
  };

  const handleFileDownload = (e) => {
    e.preventDefault();
    const file = `download/invoice/${response?.data.invoice?.id}`;
    const invoiceName = t`Facture`;
    downloadFile(
      `${file}`,
      `${invoiceName}-${response?.data?.invoice?.reference_code}`
    )
      .then((_) =>
        toast({
          description: t`Le fichier a été téléchargé avec succès`,
          status: "success",
          position: "bottom",
          duration: 10000,
          isClosable: true,
        })
      )
      .catch(console.error);
  };

  if (error) {
    history.push("/TokenError");
  }

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Flex
        bg="white"
        p="15px 12%"
        justify="space-between"
        color="gray.800"
        pos={"fixed"}
        w="full"
        zIndex="99999"
        borderBottom="2px solid #eaeaea"
        height={100}
      >
        {isLargerThan960 ? (
          <>
            <Box
              textStyle="brandHeading"
              textAlign={"left"}
              justifyContent={"center"}
            >
              <Stack spacing={0}>
                <Text fontWeight={"bold"} textTransform={"capitalize"}>
                  {response?.data.company?.name}
                </Text>
                <Text>
                  <Text as={"span"}>
                    <Trans>Référence</Trans> :
                    {response?.data.invoice?.reference_code}{" "}
                  </Text>{" "}
                  <Trans>Montant due</Trans>: {response?.data.totals?.in_number}{" "}
                  {response?.data?.currency}
                </Text>
              </Stack>
            </Box>
            <Flex justify="space-between" mt={2}>
              <Button
                mr={2}
                leftIcon={<AiOutlineDownload />}
                height={37}
                onClick={handleFileDownload}
              >
                <Trans>Télécharger le PDF</Trans>
              </Button>
              {!response?.data.invoice?.paid_at && (
                <Button
                  leftIcon={<AiOutlineCreditCard />}
                  bg={"#078942"}
                  disabled={isPaymentProcessing}
                  color={"white"}
                  height={37}
                  onClick={handlePayment}
                >
                  <Trans>Payer la facture</Trans>
                </Button>
              )}
            </Flex>
          </>
        ) : (
          <>
            <VStack
              textAlign={"center"}
              justifyContent={"center"}
              justifyItems={"center"}
              m={"auto"}
            >
              <Text fontWeight={"bold"}>{response?.data.company?.name}</Text>
              <Text fontWeight={"bold"}>
                {response?.data.totals?.in_number} {response?.data?.currency}
              </Text>
            </VStack>
          </>
        )}
      </Flex>

      {isLargerThan960 ? (
        <InvoiceContent
          token={
            response?.data?.invoice?.paid_at
              ? response?.data?.token
              : query?.token
          }
        />
      ) : (
        <InvoiceContentMobile
          datas={response?.data}
          onPaymentClick={handlePayment}
        />
      )}

      {renderModal && (
        <PaymentMethodModal
          isOpen={isOpen}
          onClose={onClose}
          invoiceToken={query.token}
          invoiceReference={response?.data.invoice?.reference_code}
        />
      )}

      <InvoiceFooter />
    </>
  );
};

export default Invoice;
