import React, {FC} from 'react';
import { VStack, HStack, Text } from '@chakra-ui/react';
import { AiOutlineCreditCard, AiOutlineMobile } from 'react-icons/ai';
import { Trans } from '@lingui/macro';

const PaymentTabs: React.FC<{
  selectedTab: number;
  onTabChange: (index: number) => void;
  handleMobileMoneyClick: () => void;
}> = ({ selectedTab, onTabChange, handleMobileMoneyClick }) => {
  return (
    <VStack alignItems="flex-start">
      <HStack spacing={4} alignItems="center" p={4}>
        <Text as="b"><Trans>Moyens de paiements</Trans></Text>
      </HStack>
      <HStack
        spacing={4}
        alignItems="center"
        p={4}
        onClick={() => onTabChange(0)}
        cursor="pointer"
        backgroundColor={selectedTab === 0 ? 'green' : 'transparent'}
        color={selectedTab === 0 ? 'white' : 'gray'}
        borderRadius={0}
        w="100%"
      >
        <AiOutlineCreditCard />
        <Text as="b">
          <Trans>Carte bancaire</Trans>
        </Text>
      </HStack>
      <HStack
        spacing={4}
        alignItems="center"
        p={4}
        onClick={handleMobileMoneyClick}
        cursor="pointer"
        backgroundColor={selectedTab === 1 ? 'green' : 'transparent'}
        color={selectedTab === 1 ? 'white' : 'gray'}
        borderRadius={0}
        w="100%"
      >
        <AiOutlineMobile />
        <Text as="b">
          <Trans>Mobile Money</Trans>
        </Text>
      </HStack>
    </VStack>
  );
};

export default PaymentTabs;
