import { useEffect } from 'react';
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener, addPaymentAbortedListener } from 'kkiapay';

// Define a custom hook for KKiaPay
export interface KKPayPayload {
    company: {
        id: number;
        country: string;
    }
    invoice: {
        amount: number;
        invoice_id: string;
        user_id: number;
    }
    customer: {
        email?: string;
        name?: string;
        phone?: string;
    }
}

const useKKiaPay = () => {
    // Function to open the KKiaPay modal
    const openKKiaPayModal = (payload: KKPayPayload) => {

        const kkiaPayData = {
            amount: payload.invoice.amount,
            email: payload.customer?.email,
            // phone: payload.customer?.phone,
            fullname: payload.customer?.name,
            data: JSON.stringify({
                user_id: payload.invoice?.user_id,
                company_id: payload.company?.id,
                invoice_id: payload.invoice?.invoice_id,
            }),
            api_key: process.env.REACT_APP_KKIAPAY_API_KEY,
            sandbox: process.env.REACT_APP_KKIAPAY_MODE == 'sandbox',
            // todo countries: payload.company?.country ? [payload.company?.country] : undefined,
        };

        // console.log('KKWIDGET DATA: ', kkiaPayData);

        openKkiapayWidget(kkiaPayData);
    };

    // Function to handle KKiaPay success
    const handleKKiaPaySuccess = (onSuccess) => {
        const successHandler = (response) => {
            // console.log(response);
            // Handle KKiaPay success response here
            if (typeof onSuccess === 'function') {
                onSuccess(response);
            }
        };

        addKkiapayListener('success', successHandler);        

        return () => {
            removeKkiapayListener('success');
        };
    };

    return { openKKiaPayModal, handleKKiaPaySuccess };
};

export default useKKiaPay;
