import * as React from 'react';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import { TokenProvider } from 'contexts/TokenContext';
import InvoiceLayout from 'components/Layout/InvoiceLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react';
import { messages } from './locales/fr/messages';
import { detect, fromNavigator } from "@lingui/detect-locale"

const DEFAULT_FALLBACK = () => "fr"

const detectedLocale = detect(
  fromNavigator(),
  DEFAULT_FALLBACK
) || 'fr';

const loadMessages = async () => {
  const langCode = detectedLocale.split('-')[0];
  await import(`./locales/${langCode}/messages.js`).then((module) => {

    i18n.load(langCode, module.messages);
    i18n.activate(langCode);
    
  });
};

export const App = () => {
  React.useEffect(() => {
    loadMessages();
  }, []);

  return (<I18nProvider i18n={i18n}>
    <Router>
      <ChakraProvider theme={theme}>
        <TokenProvider>
          <InvoiceLayout>
            <AppRouter />
          </InvoiceLayout>
        </TokenProvider>
      </ChakraProvider>
    </Router>
  </I18nProvider>);
};
