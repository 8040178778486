import * as React from 'react';
import { Flex, Box, Heading, Text, Stack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

const NotFound: React.FC = () => {
  return (
    <Stack
      color={'black'}
      bg={'white'}
      position={'relative'}
      justifyContent="center"
      justifyItems={'center'}
      height={'100%'}
    >
      <Box width={'100%'}>
        <Heading><Trans>Page non trouvée</Trans></Heading>
        <Text pt={4}>
          <Trans>Une erreur est survenue, veuillez réessayer ou contacter un administrateur Kudizy</Trans>
        </Text>
      </Box>
    </Stack>
  );
};

export default NotFound;
