import React from 'react';

type tokenPayload = {
  status: TStatus;
  invoice: IInvoice;
  company: ICompany;
  totals: ITotals;
  customer: ICustomer;
  currency: string;
};

const TokenContext = React.createContext<
  | [
      Partial<tokenPayload>,
      React.Dispatch<React.SetStateAction<Partial<tokenPayload>>>
    ]
  | undefined
>(undefined);

const TokenProvider: React.FC = (props) => {
  const [tokenPayload, setTokenPayload] = React.useState<Partial<tokenPayload>>(
    {
      status: 'TokenChecking',
      company: undefined,
      invoice: undefined,
      totals: undefined,
      customer: undefined,
      currency: undefined,
    }
  );
  return (
    <TokenContext.Provider value={[tokenPayload, setTokenPayload]} {...props} />
  );
};

const useToken = (): [
  Partial<tokenPayload>,
  React.Dispatch<React.SetStateAction<Partial<tokenPayload>>>
] => {
  const context = React.useContext(TokenContext);
  if (!context) {
    throw new Error('useToken should be used within a TokenProvider');
  }

  return context;
};

export { TokenProvider, useToken };
