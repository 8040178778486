export interface ITotals {
  sub: number;
  taxes: number;
  in_letter: string;
  in_number: number;
  after_reduction: number;
}
export interface IInvoice {
  name: string;
  reference_code: string;
  due_at: Date;
  emission_at: Date | null;
  payed_at: Date | null;
  notes: string;
  currency: string | null;
  ds_letter: string | null;
}
export interface ICompany {
  name: string;
  social_name: string | null;
  address: null | string;
  register_number: null | string;
  ifu_number: null | string;
  logo: string | null;
  postbox: string | null;
  country: string | null;
  signatory: string;
}

export interface ICustomer {
  name: string;
  email: string;
}

export const PAYMENT_PROVIDERS = {
  STRIPE: 'Stripe',
  KKIA_PAY: 'KKiaPay',
};