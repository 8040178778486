import React, {FC} from 'react';
import { Box, Flex, VStack, HStack, Text, useToast } from '@chakra-ui/react';
import StripeCheckout from './StripeCheckout';
import PaymentTabs from './PaymentTabs';
import { Trans } from '@lingui/macro';
import { t } from "@lingui/macro";

const PaymentMethods: React.FC<{
  selectedTab: number;
  onTabChange: (index: number) => void;
  onPaymentSuccess: () => void;
  invoiceToken: string;
}> = ({ selectedTab, onTabChange, onPaymentSuccess, invoiceToken }) => {
    const toast = useToast();

    const handleMobileMoneyClick = () => {
    toast({
        title: t`Les paiements par Mobile Money seront bientôt disponibles.`,
        status: 'info',
        isClosable: true,
    });
    };

    return (
    <Flex h="100%" direction={{ base: 'column-reverse', md: 'row' }}>
        <Box flex={{ base: '1', md: '8' }}>
            <Box bg="white" p={4} paddingTop={8}>
                {selectedTab === 0 && (
                            <VStack spacing={4}>
                            <StripeCheckout
                            invoiceToken={invoiceToken}
                            onSuccess={onPaymentSuccess}
                            />
                        </VStack>
                )}
                {selectedTab === 1 && (
                <Text mt={4}><Trans>Les paiements par Mobile Money seront bientôt disponibles.</Trans></Text>
                )}
            </Box>
        </Box>
        <Box flex={{ base: '1', md: '5' }} bg="green.50" paddingTop={4} paddingBottom={4}>
            <PaymentTabs
            selectedTab={selectedTab}
            onTabChange={onTabChange}
            handleMobileMoneyClick={handleMobileMoneyClick}
            />
        </Box>
    </Flex>
    );
};
         
export default PaymentMethods;
         
