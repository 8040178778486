import React from 'react';
import { Box, useColorModeValue, BoxProps } from '@chakra-ui/react';
import useWindowDimensions from 'hooks/useWindowDimensions';

type LayoutProps = {
  children?: React.ReactNode;
};

export default function InvoiceLayout(props: LayoutProps): JSX.Element {
  const { height, width } = useWindowDimensions();
  const h = height - 100;
  return (
    <Box
      bg="#eee"
      textAlign="center"
      fontSize="md"
      width={'100%'}
      height={'100vh'}
    >
      {props.children}
    </Box>
  );
}
