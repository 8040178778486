// downlaod file from s3 url and save to local
export const downloadFile = async (url: string, fileName: string) => {

  const fullUrl = `${process.env.REACT_APP_KUDIZY_API_URL}${url}`
  console.log('File', fullUrl)

  const headers = {
    'X-API-KEY': process.env.REACT_APP_KZ_PAYMENT_API_KEY || '',
  };
  
  const file = await fetch(fullUrl, { headers });
  const blob = await file.blob();
  const filePath = `${fileName}`;
  const fileUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// paydunya payment countries list allowed to use paydunya
export const paydunyaSupportedCountries =
  process.env.REACT_APP_PAYDUNYA_COUNTRIES_ALLOWED?.split(',') || [
    'BF',
    'BJ',
    'TG',
  ];

// check if country is allowed to use paydunya
export const isPaydunyaCountry = (countryCode: string) => {
  return paydunyaSupportedCountries.includes(countryCode);
};
export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
