import React, { useState, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { StripeElementLocale, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import Loader from "../Loaders/Loader";
import StripeCheckoutForm from "../Form/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

import { detect, fromNavigator } from "@lingui/detect-locale"

const DEFAULT_FALLBACK = () => "fr"

const detectedLocale = detect(
  fromNavigator(),
  DEFAULT_FALLBACK
) || 'fr';

interface StripeCheckoutProps {
  invoiceToken: string;
  onSuccess: () => void;
}

const StripeCheckout: React.FC<StripeCheckoutProps> = ({
  invoiceToken,
  onSuccess,
}) => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";
  const backendBaseUrl = process.env.REACT_APP_KUDIZY_API_URL || "";

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const headers: any = {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_KZ_PAYMENT_API_KEY,
        };
        const response = await fetch(`${backendBaseUrl}payments/create-stripe-intent/`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ token: invoiceToken }),
        });
        const data = await response.json();
        setClientSecret(data?.data?.client_secret);
      } catch (error) {
        console.error("Failed to fetch client secret:", error);
      }
    };

    fetchClientSecret();
  }, []);

  const stripePromise = loadStripe(publicKey);
  const langCode = detectedLocale.split('-')[0];
  const elementsOptions: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
    locale: langCode as StripeElementLocale,
  };

  return (
    <Box width="100%" paddingX={2}>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={elementsOptions}>
          <StripeCheckoutForm
            clientSecret={clientSecret}
            onSuccess={onSuccess}
          />
        </Elements>
      ) : (
        <Flex justifyContent="center" mt={{ base: 10, md: 40 }}>
          <Loader />
        </Flex>
      )}
    </Box>
  );
};

export default StripeCheckout;
