import React, { FC } from 'react';

import { Flex, Box, Container } from '@chakra-ui/react';

interface InvoiceContentProps {
  token: string;
}

const InvoiceContent: FC<InvoiceContentProps> = ({ token }) => {
  const htmlContentUrl = `${process.env.REACT_APP_KUDIZY_HTML_URL}${token}`;
  console.log(htmlContentUrl);
  return (
    <Flex>
      <Box w="full" mt={120} mb={30}>
        <Container bg={'white'} as={'main'}>
          <iframe height="800" width={'100%'} src={htmlContentUrl}></iframe>
        </Container>
      </Box>
    </Flex>
  );
};

export default InvoiceContent;
