import React from "react";
import { VStack, Stack, Text, Image, Button, HStack, Flex } from '@chakra-ui/react';
import SuccessMark from 'assets/success-mark.png';
import { Trans } from "@lingui/macro";

interface PaymentSuccessProps {
  invoiceReference: string;
}

const InvoicePaymentSuccess: React.FC<PaymentSuccessProps> = ({ invoiceReference }) => {
  return (
    <VStack width="100%" mt={{ base: 20, md: 24 }}>
      <Stack textAlign="center">
        <Image src={SuccessMark} alt="success-mark" height={{base: 32, md:48}} width={{base: 32, md:48}} />
      </Stack>
      <Text pt={2} fontSize={{base: 24, md:32}} color="green.400" fontWeight="semibold">
        <Trans>Facture payée avec succès !</Trans>
      </Text>
      <Text fontSize={20} color="gray.500">
        <Trans>Transaction Numéro</Trans>: {invoiceReference}
      </Text>
      <HStack >
        <Button
            bg="#078942"
            color="white"
            height={37}
            px={20}
            
            mt={4}
            onClick={() => {
              const queryParams = new URLSearchParams(window.location.search);
              const queryString = queryParams.toString();
              window.location.href = `${window.location.pathname}?${queryString}`;
            }}
          >
            OK
        </Button>
      </HStack>
    </VStack>
  );
};

export default InvoicePaymentSuccess;
