import React, { Suspense, useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
// @ts-ignore
import {
  Redirect,
  Route,
  HashRouter,
  Switch,
  BrowserRouter,
} from 'react-router-dom';

import TokenError from 'pages/TokenError';
import Invoice from 'pages/Invoice';
import NotFound from 'pages/NotFound';

const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/invoice/:token" exact component={Invoice} />

      <Route path="/TokenError" exact component={TokenError} />

      <Route path="/notFound" exact component={NotFound} />

      <Redirect from="*" to="/notFound" />
    </Switch>
  );
};

export default AppRouter;
