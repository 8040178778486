import React, { useState, useEffect } from "react";
import {
  VStack,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useStripe, useElements, LinkAuthenticationElement, PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, StripePaymentElementOptions } from "@stripe/stripe-js";
import { Trans, t } from "@lingui/macro";

interface StripeCheckoutFormProps {
  clientSecret: string;
  onSuccess: () => void;
}

const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = ({
  clientSecret,
  onSuccess,
}) => {
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const [email, setEmail] = useState<string|null>(null);
  const [message, setMessage] = useState<{
    status: "error" | "success" | "info" | "warning" | undefined;
    text: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleOnEmailChange = (event: any) => {
    setEmail(event?.target?.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "", // Make sure to change this to your payment completion page
      },
      redirect: "if_required",
    });

    if (error) {
      if (
        (error?.type === "card_error" || error?.type === "validation_error") &&
        error.message
      ) {
        setMessage({ status: "error", text: error.message });
      } else {
        setMessage({
          status: "error",
          text: t`Une erreur est survenue, veuillez réessayer.`,
        });
      }
    } else if (paymentIntent) {
      switch (paymentIntent.status) {
        case "succeeded":
          onSuccess();
          break;
        case "processing":
          setMessage({
            status: "info",
            text: t`Votre paiement est en cours de traitement.`,
          });
          break;
        case "requires_payment_method":
          setMessage({
            status: "warning",
            text: t`Paiement échoué. Veuillez réessayer.`,
          });
          break;
        default:
          setMessage(null);
          break;
      }
    }
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
    paymentMethodOrder: ["card"],
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <VStack spacing={4} p={0} m={0} alignItems="normal">
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={handleOnEmailChange}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Button
          isLoading={isLoading}
          loadingText={t`En cours...`}
          type="submit"
          bg="#078942"
          color="white"
          height={37}
        >
          <Trans>Payer</Trans>
        </Button>

        {message && (
          <Alert status={message.status}>
            <AlertIcon />
            {message.text}
          </Alert>
        )}
      </VStack>
    </form>
  );
};

export default StripeCheckoutForm;
